var navigation = document.querySelector("header .inside nav > ul");
var hamburger = navigation.querySelector(".hamburger");
if(hamburger){
    hamburger.addEventListener("click", () => {
        if(navigation.classList.contains("active")){
            navigation.classList.remove("active");
            hamburger.classList.remove("is-active");
            document.body.style.overflow = "initial";
        }else{
            document.body.style.overflow = "hidden";
            navigation.classList.add("active");
            hamburger.classList.add("is-active");
        }
    });
}