window.addEventListener("scroll", function () {
    checkScrollPosition();
}, {passive: true});

function checkScrollPosition(){
    if(window.scrollY > 150){
        document.body.classList.add("scrolled");
    }else if (window.scrollY < 50) {
        document.body.classList.remove("scrolled");
    }
}
checkScrollPosition();