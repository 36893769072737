if (document.querySelector(".ce_rsce_faq")) {
    document.querySelectorAll(".ce_rsce_faq .question-container").forEach(container => {
        container.addEventListener("click", () => {
            var answer = container.querySelector(".answer");
            if (!container.classList.contains("active")) {
                var otherAnswersOpen = false;
                document.querySelectorAll(".ce_rsce_faq .question-container").forEach(all => {
                    if(all.classList.contains("active")){
                        if(all != container){
                            all.classList.remove("active")
                            all.classList.remove("active-color");
                            collapseSection(all.querySelector(".answer"));
                        }
                    }
                });
                container.classList.add("active");
                container.classList.add("active-color");
                expandSection(answer);
            } else {
                container.classList.remove("active");
                container.classList.remove("active-color");
                collapseSection(answer);
            }
        });
    });
}

function collapseSection(element) {
    var sectionHeight = element.scrollHeight;
    var elementTransition = element.style.transition;
    element.style.transition = '';
    requestAnimationFrame(function () {
        element.style.height = sectionHeight + 'px';
        element.style.transition = elementTransition;
        requestAnimationFrame(function () {
            element.style.height = 0 + 'px';
        });
    });
    element.setAttribute('data-collapsed', 'true');
}

function expandSection(element) {
    var sectionHeight = element.scrollHeight;
    element.style.height = sectionHeight + 'px';
    element.addEventListener("transitionend", transitionEnd);
    element.setAttribute('data-collapsed', 'false');
}

function transitionEnd(event){
    if(event.target.classList.contains("active")){
        event.target.style.height = null;
    }
    event.target.removeEventListener("transitionend", transitionEnd);
}
